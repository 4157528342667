@import '../../colors.scss';

.default-collapse {
    border-radius: 0;

    & div.ant-collapse-header {
        border-radius: 0;
        padding-top: 5px;
        padding-bottom: 5px;    
    }
}

.hasIssues { 
    background-color: $HIGHLIGHT_HAS_ISSUES;
}