@import "./colors.scss";

@font-face {
  font-family: Interstate;
  font-weight: normal;
  src: url(../public/fonts/Interstate.otf);
}

@font-face {
  font-family: InterstateLight;
  font-weight: 100;
  src: url(../public/fonts/InterstateLight.otf);
}

@font-face {
  font-family: InterstateBold;
  font-weight: bold;
  src: url(../public/fonts/InterstateBold.otf);
}

* {
  font-family: 'Interstate';
}

body {
  background-color: var(--color-primary-01);
  color: $TEXT_NORMAL;
  margin: 0;
  font-family: 'Interstate', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
::-webkit-scrollbar-thumb {
  background: #888; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

// button.ant-btn {
//   &.ant-btn-default {
//     &[disabled] {
//       background: var(--color-white);
//       border-color: var(--color-neutral-06);
//       color: var(--color-neutral-06);
//     }
//   }

//   &.ant-btn-primary {
//     &[disabled] {
//       background: var(--color-neutral-06);
//       color: var(--color-neutral-08);
//     }
//   }

// }

.invisible {
  visibility: hidden;
}

.font-verdana:not(th) {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  & .ant-input, .ant-select-selection-item {
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  }
}