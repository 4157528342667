@import '../../colors.scss';

.default-collapse {
    border-radius: 0;

    & div.ant-collapse-header {
        border-radius: 0;

    }
}

.boundingbox-wrapper {
    background-color: var(--color-extended-01);
    border-radius: 4px;
    // margin-top: -45px;
    padding: 4px;
    // position: absolute;

    & div {
        border-radius: 4px;

        & div {
            border-radius: 4px;
        }

        & img {
            border-radius: 4px;
        }
    }
}

.filter-scan-header .ant-collapse-header, .scan-header .ant-collapse-header {
    height: 60px !important;
    align-items: center !important;
}

.full-view-modal {
    & .ant-modal-body {
        border: 0 !important;
    }
    & .ant-modal-content {
        padding: 14px 0 0 0 !important;
    }
    & .ant-modal-close {
        top: 11px !important;
    }
}

.loading-spin {
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100%;
}

.filter-scan-header .ant-collapse-header {
    border-bottom: 2px solid var(--color-neutral-01) !important;
}

.react-draggable .ant-modal-content {
    background-color: var(--color-primary-02) !important;
}

.react-draggable .ant-modal-header {
    margin-bottom: 0 !important;
}

.react-draggable .ant-modal-body {
    padding-top: 10px !important;
}

.ant-pagination .page-disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}

.ant-pagination .page-disabled a {
    cursor: not-allowed;
}

.ant-spin-nested-loading .ant-spin-container::after {
    background: transparent !important;
}

.delete-scans-table .ant-table-thead th.ant-table-cell {
    background: transparent !important;
    border-bottom: 2px solid #fff !important;
}

.delete-scans-table .ant-table-body {
    overflow: auto !important;
}

.delete-scans-table .ant-table-tbody .ant-table-row:hover td {
    background-color: transparent !important;
}