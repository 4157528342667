@mixin intk_heading_1 {
    & {
        font-family: 'Interstate', sans-serif;
        font-size: 18px;
        font-weight: bold;
    }
}

@mixin intk_heading_2 {
    & {
        font-family: 'Interstate', sans-serif;
        font-size: 16px;
        font-weight: bold;
    }
}

@mixin intk_heading_3 {
    & {
        font-family: 'Interstate', sans-serif;
        font-size: 16px;
        font-weight: normal;
    }
}

@mixin intk_heading_4 {
    & {
        font-family: 'Interstate', sans-serif;
        font-size: 14px;
        font-weight: normal;
    }
}

@mixin intk_body_1 {
    & {
        font-family: 'Interstate', sans-serif;
        font-size: 14px;
        font-weight: 100;
    }
}

@mixin intk_body_2 {
    & {
        font-family: 'Interstate', sans-serif;
        font-size: 12px;
        font-weight: normal;
    }
}

@mixin intk_body_3 {
    & {
        font-family: 'Interstate', sans-serif;
        font-size: 12px;
        font-weight: bold;
    }
}

@mixin intk_subtitle {
    & {
        font-family: 'Interstate', sans-serif;
        font-size: 12px;
        font-weight: 100;
    }
}

@mixin intk_button_1 {
    & {
        font-family: 'Interstate', sans-serif;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }
}