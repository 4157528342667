@import "../../../mixins.scss";

.FieldsTable-table {
    @include holdens-styled-table;

    &::after {
        // background-image: linear-gradient(to bottom, rgba(14, 38, 59, 0) 0%, var(--color-primary-02) 100%);
        width: 100%;
        height: 100px;
        position: absolute;
        bottom: 55px;
        pointer-events: none;
        z-index: 100;
    }

    &.no-shadow::after {
        background: none;
    }
}

.ant-table-row {
    & .ant-table-cell .key-asterix {
        color: var(--color-red-01);
        margin-right: 3px;
    }

    &.selected-row .ant-table-cell .key-asterix {
        color: inherit;
    }
}

.custom-checkbox {
    position: relative;
    top: 12px;
}

.custom-checkbox .ant-checkbox-inner, .custom-checkbox .ant-checkbox-checked:after {
    border-color: #fff;
}

.custom-checkbox .ant-checkbox-disabled .ant-checkbox-inner {
    background: #fff !important;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner, .custom-checkbox:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: var(--color-green-01) !important;
    border-color: var(--color-green-01) !important;
}

.custom-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner, .custom-checkbox .ant-checkbox:hover .ant-checkbox-inner, .custom-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--color-green-01);
}

.skip-label {
    position: relative;
    top: 6px;
    font-size: 12px;
    display: flex;
    text-align: center;
}

.custom-checkbox .ant-checkbox-disabled .ant-checkbox-inner:after {
    border-color: rgba(255, 255, 255, 1) !important;
}

.lock-icon-wrapper {
    border-radius: 3px;
    padding:4px 3px 2px 3px;
    height: 37px;
    width: 37px;

    &:has(span.icon-lock-hardLocked) {
        background-color: var(--color-neutral-01);
    }

    &:has(span.icon-lock-unlocked) {
        border: 1px solid var(--color-yellow-02);
        background-color: rgba(3, 22, 39, 0.2);
    }

    &:has(span.icon-lock-error-unlocked) {
        border: 1px solid var(--color-red-01);
        background-color: var(--color-primary-red-transparent);
    }

    & span:not(.ant-checkbox, .ant-checkbox-inner) {
        display: block;
        height: 22px;
        margin-left: 10px;
        margin-right: auto;
        margin-top: -2px;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        width: 16px;

        &.icon-lock-hardLocked {
            background-color: var(--color-green-02);
            mask-image: url(../../../../public/icons/lock-filled.svg);
            -webkit-mask-image: url(../../../../public/icons/lock-filled.svg);
        }

        &.icon-lock-unlocked {
            background-color: var(--color-yellow-02);
            mask-image: url(../../../../public/icons/unlock1.svg);
            -webkit-mask-image: url(../../../../public/icons/unlock1.svg);
        }

        &.icon-lock-locked {
            background-color: var(--color-white);
            mask-image: url(../../../../public/icons/lock-filled.svg);
            -webkit-mask-image: url(../../../../public/icons/lock-filled.svg);
        }

        &.icon-lock-error-unlocked {
            background-color: var(--color-red-01);
            mask-image: url(../../../../public/icons/unlock1.svg);
            -webkit-mask-image: url(../../../../public/icons/unlock1.svg);
        }
    }
}