@import "../../../mixins.scss";

.ScansTable-table {
    @include holdens-styled-table;

    ul.ant-pagination {
        @include holdens-styled-pagination;
    }

    &::after {
        // background-image: linear-gradient(to bottom, rgba(14, 38, 59, 0) 0%, var(--color-primary-02) 100%);
        width: 100%;
        height: 100px;
        position: absolute;
        // bottom: 64px;
        pointer-events: none;
    }

    &.no-shadow::after {
        background: none;
    }

    & .ant-table-body {
        overflow-y: auto !important;
    }

    & .ant-pagination-total-text {
        font-size: 15px !important;
        line-height: 33px !important;
    }

}

.width-auto-table table {
    width: auto;
}

.ant-table-thead .select-record {
    background-color: var(--color-primary-01) !important;
}

.delete-form .ant-checkbox-inner {
    border-radius: 50%;
}

.delete-form.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
    border-radius: 50%; /* Ensures round border when hovered in checked state */
}

.delete-form-btn {
    text-transform: capitalize !important;
}

.delete-form-btn:not(:disabled):hover {
    color: var(--color-secondary-01) !important;
    border-color: var(--color-secondary-01) !important;
}