@import './functions.scss';
@import './colors.scss';
@import './mixins.scss';
@import './mixins-typography.scss';
@import './theme.scss';

.page-title {
    @include intk_heading_1;
    margin-top: spacing(5);
    margin-bottom: spacing(5);
}