:root {
    /* Primary colors */
    --color-primary-01: #031627;
    --color-primary-02: #0e263b;
    --color-secondary-01: #df134c;
    --color-accent-01: #178FFe;
    --color-accent-02: #40a9ff;
    --color-accent-03: #8fa1b1;
    --color-extended-01: #354552;
    --color-extended-02: #273c4f;

    --color-primary-transparent: rgba(33, 150, 243, 0.25);
    --color-primary-red-transparent: rgba(244, 67, 54, 0.25);
    --color-primary-yellow-transparent: rgba(255, 235, 59, 0.25);

    --color-primary-03: #1976D2; // deprecated
    --color-primary-04: #1564B3; // deprecated


    /* Secondary colors */
    --color-green-01: #4CAF50;
    --color-green-02: #aadd6d;
    --color-red-01: #F44336;
    --color-red-02: #fb4737;
    --color-yellow-01: #FFEB3B;
    --color-yellow-02: #ffe600;
    --color-yellow-02-transparent-35: #5B5E19;
    --color-black: #000000;
    --color-neutral-01: rgba(255, 255, 255, 0.2);
    --color-neutral-02: #424242;
    --color-neutral-03: #616161;
    --color-neutral-04: #757575;
    --color-neutral-05: #9E9E9E;
    --color-neutral-06: #BDBDBD;
    --color-neutral-07: #E0E0E0;
    --color-neutral-08: #EEEEEE;
    --color-neutral-09: #F5F5F5;
    --color-white: #FFFFFF;
    --color-purple: #9a17fe;

    --color-match-fields-description: #757575;
    --color-match-fields-custom-item-border: rgba(255, 255, 255, 0.2);

    /* Color usage */
    --color-success: var(--color-green-01);
    --color-warning: var(--color-yellow-01);
    --color-danger: var(--color-red-01);
    --color-info: var(--color-primary-02);
}

$BG_HEADER_COL: #EEEEEE;
$BG_LAYOUT: #F5F5F5;
$BG_BUTTON_IDLE: #2296F2;
$BG_BUTTON_HOVER: #c1e4ff;

$TEXT_NORMAL: #7D7D7D;
$TEXT_DARKER: #4f4f4f;
$TEXT_BUTTON_HOVER: #f2f2f2;
$TEXT_DISABLED: #757575;

$HIGHLIGHT_HAS_ISSUES: #F8F4D0;

$HIGHLIGHT_SELECTED_ROW: #2296F2;
$HIGHLIGHT_HOVERED_ROW: #c1e4ff;

$UNLOCKED_LOCK_COLOR: #A6A7A1;
$LOCKED_LOCK_COLOR: #6AA670;
$UNLOCKED_LOCK_COLOR_WITH_ERROR: #b90000;
$COLOR_NEUTRAL: #757575;
$COLOR_WARNING_ICON: #faad14;