@mixin holdens-styled-pagination {
    &.ant-pagination {
        & .ant-pagination-item,
        & .ant-pagination-item-link,
        & .ant-pagination-jump-next,
        & .ant-pagination-jump-prev {
            background-color: var(--color-primary-01);
            border-color: var(--color-primary-01);
            box-shadow: 0 2px 0 rgba(0,0,0,0.02);
            color: var(--color-white);
            font-size: 14px;
            font-weight: 400;
            height: 32px;
            line-height: 29px;
            border-radius: 3px;
            padding: 1px 0;
            margin: 0 5px;
            position: relative;
            transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            width: 32px;

            &:not([disabled]) {
                &:hover {
                    background-color: var(--color-neutral-01);
                    color: var(--color-white);
                }
            }

            &[disabled] {
                color: var(--color-extended-01);
            }

            & .ant-pagination-item-link-icon {
                color: var(--color-white);
                border-color: var(--color-neutral-01);
            }
        }

        & .ant-pagination-item:hover {
            & a {
                background-color: var(--color-neutral-01);
                color: var(--color-white);
                border-color: var(--color-neutral-01);
            }
        }

        & .ant-pagination-item.ant-pagination-item-active {
            background-color: var(--color-primary-01);
            border: 1px solid var(--color-accent-02);
            box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);

            &:hover {
                background-color: var(--color-primary-01);
            }

            & a {
                color: var(--color-accent-02);
            }
        }

        & .ant-pagination-jump-next,
        & .ant-pagination-jump-prev {
            border: 0;
            & a {
                margin: 0 0px;
                padding: 0;    
            }
        }
    }
}

@mixin holdens-styled-table {
    &.ant-table-wrapper {
        & div.ant-table-container {
            & div.ant-table-header,
            & div.ant-table-content {
                & thead.ant-table-thead th.ant-table-cell,
                & thead.ant-table-thead td.ant-table-cell.ant-table-cell-fix-right {
                    background-color: var(--color-primary-01);
                    border-bottom: 3px solid var(--color-white);
                    color: var(--color-white);
                    font-family: 'Interstate';
                    font-size: 14px;
                    font-weight: bold;
                    height: 40px;
                    padding: 15px;

                    &:where(.secondary-table thead.ant-table-thead th.ant-table-cell),
                    &:where(.secondary-table thead.ant-table-thead td.ant-table-cell) {
                        background-color: var(--color-primary-02);
                    }
                
                    &::before {
                        background-color: transparent;
                    }

                    & .ant-table-column-sorter-down.active,
                    & .ant-table-column-sorter-up.active {
                        color: var(--color-white);
                    }

                    &.ant-table-column-sort {
                        background-color: var(--color-primary-transparent);
                    }
                }
        
            }
        }
        & .ant-table-row {
            cursor: pointer;
            height: 56px;
            &:nth-child(even) {
                background-color: var(--color-primary-01);

                &:where(.secondary-table .ant-table-row:nth-child(even)) {
                    background-color: var(--color-primary-02);
                }

                &>td.ant-table-cell.ant-table-cell-fix-right:where(.ant-table-row:not(.has-issues) .ant-table-cell.ant-table-cell-fix-right) {
                    background-color: var(--color-primary-02);
                }
            }

            &:nth-child(odd) {
                background-color: rgba(255,255,255,0.2);

                &>td.ant-table-cell.ant-table-cell-fix-right:where(.ant-table-row:not(.has-issues) .ant-table-cell.ant-table-cell-fix-right) {
                    background-color: #354552;
                }

            }

            &:nth-child(even) {
                background-color: var(--color-primary-01);

                &:where(.secondary-table .ant-table-row:nth-child(even)) {
                    background-color: var(--color-primary-02);
                }

                &>td.ant-table-cell.ant-table-cell-fix-right:where(.ant-table-row:not(.has-issues) .ant-table-cell.ant-table-cell-fix-right) {
                    background-color: var(--color-primary-02);
                }
            }

            &:nth-child(odd) {
                background-color: rgba(255,255,255,0.2);

                &>td.ant-table-cell.ant-table-cell-fix-right:where(.ant-table-row:not(.has-issues) .ant-table-cell.ant-table-cell-fix-right) {
                    background-color: #354552;
                }

            }

            &>td.ant-table-cell {
                height: 40px;
                font-family: 'Interstate';
                font-size: 14px;
                font-weight: 100;
                padding: 0 0 0 15px;
            
                & input.ant-input {
                    border-radius: 0;
                }
            
                & div.ant-select-selector {
                    border-radius: 0;
                }
            
                &:nth-of-type(1) {
                    border-left: 0px solid var(--color-white); 
                }
            
                &:nth-last-of-type(1) {
                    border-right: 0px solid var(--color-white); 
                }
            }
    
            &:hover {
                background-color: var(--color-accent-03);
                color: var(--color-white);
    
                &>td:not(.selected-row) {
                    background-color: var(--color-accent-03);
                    border-top: 1px solid var(--color-accent-03) !important; // gets overwritten byt Antd's .ant-table:not(.ant-table-bordered) rule
                    color: var(--color-white);
                    
                    &.ant-table-cell-row-hover {
                        background-color: var(--color-accent-03);
                        border-top: 1px solid var(--color-accent-03) !important; // gets overwritten byt Antd's .ant-table:not(.ant-table-bordered) rule
                        color: var(--color-white);
                
                        &:nth-of-type(1) {
                            border-left: 1px solid var(--color-accent-03); 
                        }
                
                        &:nth-last-of-type(1) {
                            border-right: 1px solid var(--color-accent-03); 
                        }
                    }

                    &:nth-of-type(1) {
                        border-left: 1px solid var(--color-primary-01); 
                    }
            
                    &:nth-last-of-type(1) {
                        border-right: 1px solid var(--color-primary-01); 
                    }
                }
            }
                
            &.selected-row,
            &.gu-transit, // to set for currently dragged row
            &:hover.selected-row,
            &:hover.selected-row>td, // to overwrite Antd's ">tr .ant-table-row:hover>td" rule
            &.selected-row.has-issues,
            &.selected-row .ant-table-cell .ant-typography { 
                color: var(--color-white);
                background-color: var(--color-accent-01) !important; 
    
                & .ant-table-cell.ant-table-cell-row-hover,
                & .ant-table-cell.ant-table-column-sort,
                & .ant-table-cell-fix-right,
                & .ant-table-cell-fix-left {
                    color: var(--color-white);
                    background-color: var(--color-accent-01) !important;
                }
            }
    
            &.has-issues,
            &:hover.has-issues { 
                background-color: var(--color-yellow-02-transparent-35);
                color: var(--color-white);
    
                & .ant-table-cell.ant-table-cell-row-hover,
                & .ant-table-cell-fix-right,
                & .ant-table-cell-fix-left {
                    background-color: var(--color-yellow-02-transparent-35);
                }
    
                & .ant-table-cell.ant-table-column-sort {
                    background-color: var(--color-yellow-02-transparent-35);
                }

                & .ant-table-cell {
                    border-top: 1px solid var(--color-yellow-01);
                    border-bottom: 1px solid var(--color-yellow-01);    

                    &:nth-of-type(1) {
                        border-left: 1px solid var(--color-yellow-01);    
                    }

                    &.ant-table-cell-row-hover {
                        background-color: var(--color-yellow-02-transparent-35);
                    }

                    &.ant-table-cell-row-hover:nth-of-type(1) {
                        border-left: 1px solid var(--color-yellow-01);    
                        border-top: 1px solid var(--color-yellow-01);
                        border-bottom: 1px solid var(--color-yellow-01);    
                    }

                    & input,
                    & .ant-select-selector {
                        background-color: rgba(62, 81, 98, 0.5);
                    }
                }           
            }    
    
            &.highlight { 
                background-color: var(--color-primary-01);
                color: var(--color-white);
            }
            
            & .ant-table-cell .ant-switch .ant-switch-inner {
                border: 1px solid var(--color-neutral-07);
            }
            & .ant-table-cell .ant-switch.ant-switch-checked .ant-switch-inner {
                border: 1px solid var(--color-neutral-06);
            }

            & .ant-table-cell.ant-table-column-sort {
                background-color: var(--color-primary-transparent);
            }
        }
    }
}