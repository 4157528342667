@import '../../colors.scss';
@import '../../mixins.scss';

.DefineForms-table {
  @include holdens-styled-table;

  & .ant-table-row .ant-table-cell .ant-select .ant-select-selection-item,
  & .ant-table-row .ant-table-cell .ant-input {
    color: var(--color-neutral-04);
  }

  & .ant-table-row.selected-row {
    & .ant-table-cell .ant-select .ant-select-selection-item,
    & .ant-table-cell .ant-input.confidence-input {
      color: var(--color-neutral-02);
    }
  }
}

// .ant-table-row.highlight {
//   & td {
//     color: var(--color-white);
//     background-color: var(--color-primary-03);
  
//     &:hover {
//       background-color: var(--color-primary-03);
//     }
//   }
// }

.define-forms-image-section::-webkit-scrollbar {
  display: none;
}

.ant-table-row .draggable {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    font-size: 1.2rem;
    line-height: 100%;
    padding-top:5px;
  }

  .gu-mirror {
    visibility: hidden;
  }
  .gu-hide {
    display: none !important;
  }
  .gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
  .gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
  }

  .ant-tabs .ant-tabs-nav {
    margin-bottom: 0;
    & .ant-tabs-tab {
      border: 0;
      background-color: transparent;
      padding: 16px 50px;
      & .ant-tabs-tab-btn {
        color: var(--color-accent-03);
        &:hover {
          color: #fff;
        }
      }
    }
    & .ant-tabs-tab-active {
      background-color: var(--color-primary-02);
      & .ant-tabs-tab-btn {
        color: #fff;
      }
    }
  }
  
  #DefineForms_Table_table .ant-table-thead .draggable {
    background: var(--color-primary-01);
    border-bottom: 3px solid var(--color-white);
  }

  .ant-select-selector {
    color: #fff !important;
    height: 40px !important;
    background-color: rgba(255, 255, 255, 0.2) !important;
    border-radius: 4px !important;
    & .ant-select-selection-item {
      color: #fff !important;
    }
    & input {
      height: 40px !important;
    }
    & .ant-select-selection-placeholder, .ant-select-selection-item {
      line-height: 40px !important;
    }
    & .ant-select-selection-overflow-item {
      top: -2px;
      position: relative;
      & .ant-select-selection-item {
        line-height: 24px !important;
      }
    }
  }

  .ant-select-dropdown {
    & .ant-select-item-option:not(.ant-select-item-option-disabled,.ant-select-item-option-selected):hover {
      background-color: var(--color-accent-03) !important;
    }
    & .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: transparent !important;
    }
    & .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: var(--color-accent-01) !important;
    }
    & .ant-space {
      width: 100%;
      overflow-x: auto;
    }
  }

  #define-forms-form .ant-upload-list-item {
    & .ant-upload-icon {
      display: none;
    }
    & .ant-upload-list-item-actions {
      display: none;
    }
    &:hover {
      background-color: transparent !important;
    }
  }

  #define-forms-form .ant-progress-text {
    position: absolute;
    top: -19px;
    right: 38px;
  }
    
  .ant-select-clear {
    background: transparent !important;
    color: #fff !important;
  }

  .ant-modal-confirm-content {
    width: 100%;
  }

  .ant-select-dropdown {
    padding: 8px 0 !important;
    background: #031627 !important;
    border: 1px solid #fff !important;
    border-radius: 4px !important;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #8fa1b1;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #178ffe;
  }

  .ant-select-item-option-content {
    color: #fff !important;
  }

  .uploading-file .ant-upload-list-item-name {
    padding: 0;
    max-width: 80%;
  }

  .has-file {
    padding: 12px 16px;
    border-radius: 4px;
    border: solid 2px rgba(255, 255, 255, 0.5);
  }

  .img-uploaded {
    max-height: 66px;
    display: flex;
    align-items: center;
  }

  .close-circle {
    right: 5px;
    top: 16px;
    position: absolute;
  }

  .uploading .close-circle {
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 24px !important;
  }

  .edit-form {
    padding-left: 0 !important;
  }

  .page-select .ant-select-selection-placeholder {
    color: #fff;
  }