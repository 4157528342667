@import '../../../colors.scss';

.match-fields-columns-editable {
    .custom-list {
        .ant-list-items {
            > li {
                background-color: rgba(255, 255, 255, 0);//xxx;
            }
            > li:nth-child(2n) {
                background-color: rgba(255, 255, 255, 0);//xxx
            }
        }
    }
}

.custom-list {
    border: none;
    height: 100%;
    .ant-list-items {
        > li {
            border: 1px solid var(--color-match-fields-custom-item-border);
            background-color: rgba(255, 255, 255, 0.2);//xxx
            margin-bottom: 3px;
            border-radius: 5px;
            cursor: pointer;
        }
        > li:nth-child(2n) {
            background-color: rgba(255, 255, 255, 0.35);//xxx
        }
        > li.custom-list-item-disabled {
            cursor: not-allowed;
            border-color: rgba(255, 255, 255, 0.25);
        }
        > li label span.ant-radio-disabled {
            > input {
                cursor: not-allowed;
            }
            > .ant-radio-inner {
                border-color: rgba(255, 255, 255, 0.25);
            }
        }
        > li:last-child {
            border-bottom: 1px solid var(--color-match-fields-custom-item-border);
        }
    }
    .ant-list-empty-text {
        display: none;
    }
}

.middle-custom-list {
    .ant-list-items {
        > li {
            border-left: none;
        }
    }
    .ant-list-empty-text {
        display: none;
    }
}

.list-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
}

.match-fields-list-height {
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.match-fields-list-height::-webkit-scrollbar {
    display: none;
}

.match-fields-left-list-radio {
    float: right;
}

.match-fields-buttons-wrapper {
    text-align: center;
}

.match-fields-styled-space {
    margin-top: 15px;
    > .ant-space-item {
        padding-right: 0px !important;
    }
}

.match-fields-base {
    padding-right: 16px;
}

.match-fields-list-header {
    border-radius: 4px;
}

.match-fields-list-header-left {
    margin-right: 2px;
}

.match-fields-list-header-right {
    margin-left: 2px;
}

.match-fields-title {
    margin-bottom: 8px;
    font-family: Interstate;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--color-white);
}

.match-fields-radio {
    & .ant-radio {
        > .ant-radio-inner {
            background-color: rgba(0,0,0,0);
        }
        > .ant-radio-inner::after {
            background-color: var(--color-white);
            width: 20px;
            height: 20px;
            margin-block-start: -10px;
            margin-inline-start: -10px;
        }
    }
    & .ant-radio-checked {
        > .ant-radio-inner {
            background-color: var(--color-accent-01);
            border-color: #fff;
        }
    }
}

.match-fields-columns {
    height: calc(100% - 150px);
}

.match-fields-view-columns {
    height: calc(100% - 90px);
}

.match-fields-columns-editable {
    height: calc(100% - 240px);
}

// @media (min-width: 1240px) {
//     .match-fields-columns-editable {
//         height: 69%;
//     }
// }

@media (min-width: 768px) and (max-width: 1239px) {
    .match-fields-list-header {
        padding: 10px 10px !important;
    }
    // .match-fields-columns-editable {
    //     height: 68%;
    // }
}